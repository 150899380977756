declare var gsap : any;
declare var $ : any;
declare var Parallax:any;

function navMobileHandle(){
    let hamburger = <HTMLElement>document.querySelector('.mobile-hamburger');
    let navMenu = <HTMLElement> document.querySelector('.mobile-nav');
    if (hamburger && navMenu){
        let subMenu = navMenu.querySelectorAll('.nav-menu-item');

        hamburger.onclick = function(e){
            e.preventDefault();

            if (hamburger.classList.contains('show')){
                hamburger.classList.remove('show');
                navMenu.classList.remove('show');
            }else{
                hamburger.classList.add('show');
                navMenu.classList.add('show');
            }
        }

        let close = <HTMLElement>navMenu.querySelector('.close');
        if (close){
            close.onclick = function(e){
                e.preventDefault();

                hamburger.classList.remove('show');
                navMenu.classList.remove('show');
            }
        }

        document.addEventListener('click', function(e){
            if (e.target === navMenu){
                hamburger.classList.remove('show');
                navMenu.classList.remove('show');
            }

            subMenu.forEach(function(sub){
                let link = sub.querySelector('.nav-menu__link');
                if (e.target === link){
                    if (sub.classList.contains('active')){
                        sub.classList.remove('active');
                    }else{
                        subMenu.forEach(function(sub){
                            sub.classList.remove('active');
                        })
                        sub.classList.add('active');
                    }
                }
            })
        })
    
    
        subMenu.forEach(function(sub){
            let link = sub.querySelector('.nav-menu__link a');
            link.addEventListener('click', function(e){
                let hrefE = link.getAttribute('href');

                if (hrefE === 'javascript:void(0)' || hrefE === '' || hrefE === '#'){
                    e.preventDefault();
                    if (sub.classList.contains('active')){
                        sub.classList.remove('active');
                    }else{
                        subMenu.forEach(function(sub){
                            sub.classList.remove('active');
                        })
                        sub.classList.add('active');
                    }
                }
            })

            let svg = sub.querySelector('.nav-menu__link svg');
            if (svg){
                svg.addEventListener('click', function(){
                    if (sub.classList.contains('active')){
                        sub.classList.remove('active');
                    }else{
                        subMenu.forEach(function(sub){
                            sub.classList.remove('active');
                        })
                        sub.classList.add('active');
                    }
                })
            }
        })
    }
}

function scrollHeader(){
    let header = document.querySelector('.header');
    let tools = document.querySelector('.tools');
    if (!header){
        return;
    }

    let hHeader = header.clientHeight;
    let timeoutScroll:any;
    let timeoutRs: any;

    let banner = <HTMLElement>document.querySelector('.banner');
    let mainSection = <HTMLElement>document.querySelector('main');
    if(banner){
        banner.style.marginTop = hHeader + "px";
    }
    else{
        if (mainSection){
            mainSection.style.marginTop = hHeader + "px";
        }
    }
    // lấy section sau của header
    // let nextHeader = <HTMLElement>header.nextElementSibling;
    // if (nextHeader){
    //     nextHeader.style.marginTop = hHeader + "px";
    // }
    // tạo sự kiện scroll
    // khi scrollY lớn hơn header clientHeight thì cho header có position fixed

    window.addEventListener('scroll', function(e){
        this.clearTimeout(timeoutScroll);
        
        timeoutScroll = this.setTimeout(function(){
            if (window.scrollY > hHeader){
                header.classList.add('scroll');
                if (tools){
                    tools.classList.add('scroll');
                }
            }
            else{
                header.classList.remove('scroll');
                tools.classList.remove('scroll');
            }
        }, 200)
    })

    window.addEventListener('resize', function(e){
        this.clearTimeout(timeoutScroll);

        timeoutRs = this.setTimeout(function(){
            hHeader = header.clientHeight;
            // if (nextHeader){
            //     nextHeader.style.marginTop = hHeader + "px";
            // }
            if(banner){
                banner.style.marginTop = hHeader + "px";
            }
            else{
                if (mainSection){
                    mainSection.style.marginTop = hHeader + "px";
                }
            }
        }, 200)
    });
}

export const header = function(){
    navMobileHandle();
    scrollHeader();
}
