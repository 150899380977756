import base from "../libraries/_base";


function tabsHandle(){
    let tabs = document.querySelectorAll('.lib-1-tabs .tab-item');
    let swOpts = document.querySelectorAll('.lib-1-option');
    tabs.forEach(function(tab, idx){
        let itemTab = <HTMLElement> tab;
        itemTab.onclick = function(){
            base.delHandle(tabs, 'active');
            base.delHandle(swOpts, 'active');

            tab.classList.add('active');

            swOpts[idx].classList.add('active');
            initPagination();
        }
    })
}

import pagination from "../libraries/pagination";

function initPagination(){
    if (window.innerWidth >= 992){
        pagination.init({
            queryClassPagination: '.lib-1-option.active .box-pagination',
            listBox: '.lib-1-option.active .lib-1-option__item',
            itemPages: 9,
            scroll: {
                id: 'scrollBoxPagination',
            }
        })
    }
    else if (window.innerWidth >= 576){
        pagination.init({
            queryClassPagination: '.lib-1-option.active .box-pagination',
            listBox: '.lib-1-option.active .lib-1-option__item',
            itemPages: 6,
            scroll: {
                id: 'scrollBoxPagination',
            }
        })
    }
    else{
        pagination.init({
            queryClassPagination: '.lib-1-option.active .box-pagination',
            listBox: '.lib-1-option.active .lib-1-option__item',
            itemPages: 3,
            scroll: {
                id: 'scrollBoxPagination',
            }
        })
    }
    let timeOutRs: any;
    window.addEventListener('resize', function(e){
        clearTimeout(timeOutRs);
        setTimeout(function(){
            if (window.innerWidth >= 992){
                pagination.init({
                    queryClassPagination: '.lib-1-option.active .box-pagination',
                    listBox: '.lib-1-option.active .lib-1-option__item',
                    itemPages: 9,
                    scroll: {
                        id: 'scrollBoxPagination',
                    }
                })
            }
            else if (window.innerWidth >= 576){
                pagination.init({
                    queryClassPagination: '.lib-1-option.active .box-pagination',
                    listBox: '.lib-1-option.active .lib-1-option__item',
                    itemPages: 6,
                    scroll: {
                        id: 'scrollBoxPagination',
                    }
                })
            }
            else{
                pagination.init({
                    queryClassPagination: '.lib-1-option.active .box-pagination',
                    listBox: '.lib-1-option.active .lib-1-option__item',
                    itemPages: 3,
                    scroll: {
                        id: 'scrollBoxPagination',
                    }
                })
            }
        }, 300)
    })
}

export const libraries = function(){
    tabsHandle();
    initPagination();
}