declare var Fancybox:any;

function init(){
    Fancybox.bind('[data-fancybox="gallery"]', {
        Thumbs: {
          Carousel: {
            fill: false,
            center: true,
          },
        },

        Image: {
          zoom: true,
        },
    });
    Fancybox.bind('[data-fancybox="gallery-2"]', {
        Thumbs: {
          Carousel: {
            fill: false,
            center: true,
          },
        },

        Image: {
          zoom: true,
        },
    });
    Fancybox.bind('[data-fancybox="gallery-3"]', {
        Thumbs: {
          Carousel: {
            fill: false,
            center: true,
          },
        },

        Image: {
          zoom: true,
        },
    });
    Fancybox.bind('[data-fancybox="gallery-video"]', {
        Thumbs: {
          Carousel: {
            fill: false,
            center: true,
          },
        },

        // Image: {
        //   zoom: true,
        // },
    });
}

export const fancyboxLib = function(){
    init();
}