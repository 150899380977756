import pagination from "../libraries/pagination";
import base from "../libraries/_base";


function tabsHandle(){
    let tabs = document.querySelectorAll('.si-1 .button-choose');
    let swOpts = document.querySelectorAll('.si-1-wrapper .si-1__section');

    tabs.forEach(function(itemTemp, idxTemp){
        if (idxTemp == 0){
            itemTemp.classList.add('active');
        }else{
            itemTemp.classList.remove('active');
        }
    })
    swOpts.forEach(function(itemTemp, idxTemp){
        if (idxTemp == 0){
            itemTemp.classList.add('active');
        }else{
            itemTemp.classList.remove('active');
        }
    })

    tabs.forEach(function(tab, idx){
        let itemTab = <HTMLElement> tab;
        itemTab.onclick = function(){
            base.delHandle(tabs, 'active');
            base.delHandle(swOpts, 'active');

            tab.classList.add('active');

            swOpts[idx].classList.add('active');
        }
    })
}


export const searchesInfo = function(){
    tabsHandle();
}