import pagination from "../libraries/pagination";
import base from "../libraries/_base";

function tabsHandle(){
    let tabs = document.querySelectorAll('.f-1-tabs .tab-item');
    let swOpts = document.querySelectorAll('.f-1-wrapper .f-1-opt');

    tabs.forEach(function(itemTemp, idxTemp){
        if (idxTemp == 0){
            itemTemp.classList.add('active');
        }else{
            itemTemp.classList.remove('active');
        }
    })
    swOpts.forEach(function(itemTemp, idxTemp){
        if (idxTemp == 0){
            itemTemp.classList.add('active');
        }else{
            itemTemp.classList.remove('active');
        }
    })

    tabs.forEach(function(tab, idx){
        let itemTab = <HTMLElement> tab;
        itemTab.onclick = function(){
            base.delHandle(tabs, 'active');
            base.delHandle(swOpts, 'active');

            tab.classList.add('active');

            swOpts[idx].classList.add('active');

            initPagination();
        }
    })
}

function initPagination(){
    if (document.querySelector('.f-1')){
            pagination.init({
                queryClassPagination: '.f-1-wrapper .f-1-opt.active .box-pagination',
                listBox: '.f-1-wrapper .f-1-opt.active .box-download',
                itemPages: 6,
                scroll: {
                    id: 'scrollBoxPagination',
                }
            })
        let timeOutRs: any;
        window.addEventListener('resize', function(e){
            clearTimeout(timeOutRs);
            setTimeout(function(){
                pagination.init({
                    queryClassPagination: '.f-1-wrapper .f-1-opt.active .box-pagination',
                    listBox: '.f-1-wrapper .f-1-opt.active .box-download',
                    itemPages: 6,
                    scroll: {
                        id: 'scrollBoxPagination',
                    }
                })
            }, 300)
        })
    }
}

export const formsPage = function(){
    tabsHandle();
    initPagination();
}