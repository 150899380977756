import base from "../libraries/_base";
declare var Swiper : any;

// declare var delHandle: any;

function initSwiper(){
    let idx2Swiper = document.querySelector('.idx-2');
    if (idx2Swiper){
        new Swiper(idx2Swiper.querySelector('.swiper'), {
            slidesPerView: 3,
            spaceBetween: 38,
            speed: 800,

            autoplay: {
                delay: 2000,
                disableOnInteraction: false,
            },

            navigation: {
                nextEl: idx2Swiper.querySelector('.swiper-custom-button-next'),
                prevEl: idx2Swiper.querySelector('.swiper-custom-button-prev')
            },

            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 12,
                },
                576: {
                    slidesPerView: 1.5,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                992: {
                    slidesPerView: 2.25,
                    spaceBetween: 20,
                },
                1200: {
                    slidesPerView: 2.5,
                    spaceBetween: 20,
                },
                1336: {
                    slidesPerView: 3,
                    spaceBetween: 38,
                }
            }
        })
    }
    let idx3Swiper = document.querySelector('.idx-3');
    if (idx3Swiper){
        new Swiper(idx3Swiper.querySelector('.swiper'), {
            slidesPerView: 4,
            spaceBetween: 134,
            speed: 800,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            navigation: {
                nextEl: idx3Swiper.querySelector('.swiper-custom-button-next'),
                prevEl: idx3Swiper.querySelector('.swiper-custom-button-prev')
            },

            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                },
                420: {
                    slidesPerView: 2,
                    spaceBetween: 60,
                },
                576: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 100,
                },
                992: {
                    slidesPerView: 4,
                    spaceBetween: 80,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 100,
                },
                1336: {
                    slidesPerView: 4,
                    spaceBetween: 134,
                }
            }
        })
    }

    idx4Swiper();

    let idx5Swiper1 = document.querySelector('.idx-5-swiper1');
    if (idx5Swiper1){
        new Swiper(idx5Swiper1.querySelector('.swiper'), {
            slidesPerView: 4,
            spaceBetween: 28,
            loop: true,
            speed: 3000,
            // freeMode: true,
            autoplay: {
                delay: 0,
                disableOnInteraction: false,
                stopOnLastSlide: true,
                // pauseOnMouseEnter: true
            },

            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                420: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                576: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                992: {
                    slidesPerView: 3.25,
                    spaceBetween: 16,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 16,
                },
                1336: {
                    slidesPerView: 4,
                    spaceBetween: 28,
                }
            }
        })
    }

    let idx5Swiper2 = document.querySelector('.idx-5-swiper2');
    if (idx5Swiper2){
        new Swiper(idx5Swiper2.querySelector('.swiper'), {
            slidesPerView: 4,
            spaceBetween: 28,
            loop: true,
            speed: 3000,
            // freeMode: true,
            autoplay: {
                delay: 0,
                disableOnInteraction: false,
                stopOnLastSlide: true,
                reverseDirection: true,
                // pauseOnMouseEnter: true
            },

            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                420: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                576: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                992: {
                    slidesPerView: 3.25,
                    spaceBetween: 16,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 16,
                },
                1336: {
                    slidesPerView: 4,
                    spaceBetween: 28,
                }
            }
        })
    }
}

function idx4Swiper(){
    let idx4Swiper = document.querySelector('.idx-4-swiper.active');
    if (idx4Swiper){
        new Swiper(idx4Swiper.querySelector('.swiper'), {
            slidesPerView: 3,
            spaceBetween: 38,
            speed: 1200,
            
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
                // pauseOnMouseEnter: true
            },
            navigation: {
                nextEl: idx4Swiper.querySelector('.swiper-custom-button-next'),
                prevEl: idx4Swiper.querySelector('.swiper-custom-button-prev')
            },

            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 12,
                },
                576: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1336: {
                    slidesPerView: 3,
                    spaceBetween: 38,
                }
            }
        })
    }
}


// function delHandle(list:any,str:any){
//     if (typeof str == "string"){
//         list.forEach(function(item:HTMLElement){
//             item.classList.remove(str);
//         })
//     }
// }

function tabsHandle(){
    let tabs = document.querySelectorAll('.idx-4-tabs .tab-item');
    let swOpts = document.querySelectorAll('.idx-4-swiper');
    tabs.forEach(function(tab:HTMLElement, idx){
        tab.onclick = function(){
            base.delHandle(tabs, 'active');
            base.delHandle(swOpts, 'active');

            tab.classList.add('active');

            swOpts[idx].classList.add('active');
            idx4Swiper();
        }
    })
}

function heightMapHandle(){
    let map = <HTMLElement>document.querySelector('.idx-6-maps');
    let contentMap = <HTMLElement>document.querySelector('.idx-6-content');
    if (map && contentMap){
        if (window.innerWidth >= 992){
            contentMap.style.height = map.clientHeight + "px";
        }
        
        window.addEventListener('resize', function(){
            if (window.innerWidth >= 992){
                contentMap.style.height = map.clientHeight + "px";
            }   
        })
    }
}

function linkMapHandle(){
    let links = document.querySelectorAll('.idx-6-content .item-content h4 a');
    let timeOutLink:any;

    links.forEach(function(link:HTMLElement){
        link.onmouseover = function(e){
            let map = document.querySelector('.idx-6-maps');
            let pos = link.getAttribute('data-map');
            if (!pos){
                return;
            }
            let posAnimations = map.querySelectorAll('.' + pos);
            posAnimations.forEach(function(item:HTMLElement){
                item.classList.add('animate__bounce')
            })
            timeOutLink = setTimeout(() => {
                base.delHandle(posAnimations, 'animate__bounce');
            }, 1000);
        }
        // link.onmouseout = function(e){
        //     let timeOutLink:any;
        //     if (link.getAttribute('href') == "javascript:void(0)"){
        //         e.preventDefault();
        //         let map = document.querySelector('.idx-6-maps');
        //         let pos = link.getAttribute('data-map');
        //         let posAnimations = map.querySelectorAll('.' + pos);
        //         posAnimations.forEach(function(item:HTMLElement){
        //             item.classList.remove('animate__bounce')
        //         })
        //     }else{
        //         return true;
        //     }
        // }
        // link.onclick = function(e){
        //     let timeOutLink:any;
        //     if (link.getAttribute('href') == "javascript:void(0)"){
        //         e.preventDefault();
        //         let map = document.querySelector('.idx-6-maps');
        //         let pos = link.getAttribute('data-map');
        //         let posAnimations = map.querySelectorAll('.' + pos);
        //         posAnimations.forEach(function(item:HTMLElement){
        //             item.classList.add('animate__bounce')
        //         })
        //         setTimeout(() => {
        //             delHandle(posAnimations, 'animate__bounce');
        //         }, 1000);
        //     }else{
        //         return true;
        //     }
        // }
    })
}

export const index = function(){
    initSwiper();
    tabsHandle();
    heightMapHandle();
    linkMapHandle();
}