// https://fancyapps.com/docs/ui/fancybox
// https://www.npmjs.com/package/axios
// https://greensock.com/
// https://swiperjs.com/get-started

declare var Swiper : any;
declare var $ : any;
declare var AOS : any;
declare var gsap : any;
declare var Fancybox:any;
declare var Carousel:any;
declare var Panzoom:any;

// Fancybox.defaults.ScrollLock = false;

// AOS.init({
//   once: true
// });

// import axios from "axios";

//////////////////////////////////////////////////////////
import base from "./libraries/_base";
import {_imgClass} from "./libraries/_imgClass";

// Common function
import {sw} from "./libraries/swiper";
import constants from "./components/_constants";
import {fancyboxLib} from "./components/fancybox-lib";

// import {validator} from "./libraries/_validator";

// Layout
import {header} from "./layout/header";
import {footer} from "./layout/footer";
import {banner} from "./layout/banner";
import {breadcrumbs} from "./layout/breadcrumbs";
import {popup} from "./layout/popup";
import {tools} from "./layout/tools";
import {_scrollToTop} from "./layout/scrollToTop";

// Page
import {index} from "./pages/index";
import {about} from "./pages/about";
import {news} from "./pages/news";
import {newsDetail} from "./pages/news-detail";
import {services} from "./pages/services";
import {structure} from "./pages/structure";
import {careers} from "./pages/careers";
import {libraries} from "./pages/libraries";
import {incoterms} from "./pages/incoterms";
import {knowledgeContainer} from "./pages/knowledge-container";
import {knowledgeDictionary} from "./pages/knowledge-dictionary";
import {formsPage} from "./pages/forms";
import {searchesInfo} from "./pages/searches-info";
import {submitSi} from "./pages/shipping-instruction";
import {quickTracking} from "./pages/quick-tracking";

// import {contact} from "./pages/contact";

_imgClass();

///////////////////////////////////////////////////////////////
window.addEventListener('DOMContentLoaded', function() {

  // ----- Common in here ----- //
  sw();
  constants();fancyboxLib();
  // validator();
  // ----- Layout in here ----- //
  header(), footer();breadcrumbs();banner();tools();_scrollToTop();
  // ----- Page in here ----- //
  index();news();newsDetail();about();services();structure();careers();libraries();
  incoterms();knowledgeContainer();knowledgeDictionary();formsPage();searchesInfo();submitSi();popup();
  quickTracking();
})

/////////////////////WEB FULLPAGE///////////////////////////
// import loadingAni from "./initAnimation/loading";

// const fullpageLoad = function(){
//   let loading = document.querySelector('#loading-container');
//   loadingAni.aniIn();
//   // import animateFullpage from "./components/animateFullpage";
//   // animateFullpage.default();
  
//   window.addEventListener('load', function() {
//     // swiper_nav();
//     allJs();
//     setTimeout(function(){
//       loading.classList.add('hiddenLoading');
//       setTimeout(function(){
//         loading.parentElement.removeChild(loading);

//         // let utiSubMap = document.getElementById('UtilitiesSubPage');
//         // if (utiSubMap){
//         //   let map = utiSubMap.querySelector('.map');
//         //   map.classList.add('animateActive');
//         // }
//       }, 700)
//       common();
//     }, loadingAni.delay)
//   })
// }

// fullpageLoad();

/////////////////////WEB NORMAL///////////////////////////

