import pagination from "../libraries/pagination";
import base from "../libraries/_base";

function activeTabHandle(){
    if(document.querySelector('.k-dictionary')){
        let tabs = document.querySelectorAll('.k-dictionary .tab-item');
        let dictionaryOpts = document.querySelectorAll('.k-dictionary .block-dictionary-opts')
        if (tabs.length > 0){
            tabs[0].classList.add('active');
        }
        if (dictionaryOpts.length > 0){
            dictionaryOpts[0].classList.add('active');
        }

        // initPagination();
        tabs.forEach(function(itemTab){
            itemTab.addEventListener('click', function(e){
                tabs.forEach(function(temp){
                    temp.classList.remove('active');
                })
                itemTab.classList.add('active');
                
                let dtTab = itemTab.getAttribute('data-tab');
                dictionaryOpts.forEach(function(itemOpt){
                    if(itemOpt.getAttribute('data-tab') == dtTab){
                        itemOpt.classList.add('active');
                        // initPagination();
                    }
                    else{
                        itemOpt.classList.remove('active');
                    }
                })
            })
        })
    }
}

// function initPagination() {
//     if (document.querySelector(".k-dictionary")) {
// 		pagination.init({
// 			queryClassPagination: ".k-dictionary .block-dictionary-opts.active .box-pagination",
// 			listBox: ".k-dictionary .block-dictionary-opts.active .content-item",
// 			itemPages: 9,
// 			scroll: {
// 				id: "scrollBoxPagination",
// 			},
// 		});

// 		let timeOutRs: any;
// 		window.addEventListener("resize", function (e) {
// 			clearTimeout(timeOutRs);
// 			setTimeout(function () {
// 				pagination.init({
// 					queryClassPagination: ".k-dictionary .block-dictionary-opts.active .box-pagination",
// 					listBox: ".k-dictionary .block-dictionary-opts.active .content-item",
// 					itemPages: 9,
// 					scroll: {
// 						id: "scrollBoxPagination",
// 					},
// 				});
// 			}, 300);
// 		});
// 	}
// }

export const knowledgeDictionary = function () {
	activeTabHandle();
};
