function scrollToTop(){
    let stt = <HTMLElement>document.querySelector('.scroll-to-top');
    if (stt){
        stt.onclick = function(){
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            })
        }

        let footer = document.querySelector('.footer');
        if (!footer){
            return;
        }

        stt.style.display = "none";
        let timeoutScroll:any;

        const body = document.body;
        const footerFixed = document.querySelector('.footer-fixed');

        window.addEventListener('scroll', function(e){
            // console.log(offsetFooter);
            this.clearTimeout(timeoutScroll);
            timeoutScroll = this.setTimeout(function(){    
                if (window.scrollY >= body.clientHeight / 2){
                    stt.style.display = "";
                    if (footerFixed){
                        footerFixed.classList.add("showed-scroll")
                    }
                }
                else{
                    stt.style.display = "none";
                    if (footerFixed){
                        footerFixed.classList.remove("showed-scroll")
                    }
                }
            }, 200)
        })
    }
}

export const _scrollToTop = function(){
    scrollToTop();
}