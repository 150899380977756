import pagination from "../libraries/pagination";
import base from "../libraries/_base";

function initPagination() {
    if (document.querySelector(".kc-1")) {
		pagination.init({
			queryClassPagination: ".kc-1-wrapper .box-pagination",
			listBox: ".kc-1-wrapper .kc-1-wrapper__item",
			itemPages: 3,
			scroll: {
				id: "scrollBoxPagination",
			},
		});

		let timeOutRs: any;
		window.addEventListener("resize", function (e) {
			clearTimeout(timeOutRs);
			setTimeout(function () {
				pagination.init({
					queryClassPagination: ".kc-1-wrapper .box-pagination",
					listBox: ".kc-1-wrapper .kc-1-wrapper__item",
					itemPages: 3,
					scroll: {
						id: "scrollBoxPagination",
					},
				});
			}, 300);
		});
	}
}

export const knowledgeContainer = function () {
	initPagination();
};
