declare var Swiper : any;
function initSwiper(){
    let about3Swiper = document.querySelector('.about-3-swiper');
    if (about3Swiper){
        new Swiper(about3Swiper.querySelector('.swiper'), {
            slidesPerView: 3,
            spaceBetween: 38,
            speed: 1800,
            // loop: true,
            autoplay: {
                delay: 800,
                disableOnInteraction: false,
                pauseOnMouseEnter: true
            },

            navigation: {
                nextEl: about3Swiper.querySelector('.swiper-custom-button-next'),
                prevEl: about3Swiper.querySelector('.swiper-custom-button-prev')
            },

            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 12,
                },
                576: {
                    slidesPerView: 1.5,
                    spaceBetween: 16,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                },
                992: {
                    slidesPerView: 2.25,
                    spaceBetween: 20,
                },
                1200: {
                    slidesPerView: 2.5,
                    spaceBetween: 20,
                },
                1336: {
                    slidesPerView: 3,
                    spaceBetween: 38,
                }
            }
        })
    }
    let about3Swiper2 = document.querySelector('.about-3-swiper2');
    if (about3Swiper2){
        new Swiper(about3Swiper2.querySelector('.swiper'), {
            slidesPerView: 3,
            spaceBetween: 38,
            speed: 1800,
            // loop: true,
            autoplay: {
                delay: 800,
                disableOnInteraction: false,
                pauseOnMouseEnter: true
            },

            navigation: {
                nextEl: about3Swiper2.querySelector('.swiper-custom-button-next'),
                prevEl: about3Swiper2.querySelector('.swiper-custom-button-prev')
            },

            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 12,
                },
                576: {
                    slidesPerView: 1.5,
                    spaceBetween: 16,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                },
                992: {
                    slidesPerView: 2.25,
                    spaceBetween: 20,
                },
                1200: {
                    slidesPerView: 2.5,
                    spaceBetween: 20,
                },
                1336: {
                    slidesPerView: 3,
                    spaceBetween: 38,
                }
            }
        })
    }
    
    let about4Swiper = document.querySelector('.about-4');
    if (about4Swiper){
        new Swiper(about4Swiper.querySelector('.swiper'), {
            slidesPerView: 3,
            spaceBetween: 38,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
                pauseOnMouseEnter: true
            },

            navigation: {
                nextEl: about4Swiper.querySelector('.swiper-custom-button-next'),
                prevEl: about4Swiper.querySelector('.swiper-custom-button-prev')
            },

            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 12,
                },
                576: {
                    slidesPerView: 1.5,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                992: {
                    slidesPerView: 2.25,
                    spaceBetween: 20,
                },
                1200: {
                    slidesPerView: 2.5,
                    spaceBetween: 20,
                },
                1336: {
                    slidesPerView: 3,
                    spaceBetween: 38,
                }
            }
        })
    }
}

import base from "../libraries/_base";

function delHandle(list:any,str:any){
    if (typeof str == "string"){
        list.forEach(function(item:HTMLElement){
            item.classList.remove(str);
        })
    }
}

function statementsHandle(){
    let opts= document.querySelectorAll('.about-2 .statements-opts');
    let selects = document.querySelectorAll('.about-2 .statements-btns__item');
    let imgs = document.querySelectorAll('.about-2 .left img[data-select]');

    if (opts.length > 0 && selects.length > 0){
        opts[0].classList.add('active');
        selects[0].classList.add('active');
        imgs[0].classList.add('active');
    }

    selects.forEach(function(sel,idxSel){
        sel.addEventListener('click', function(e){
            e.preventDefault();

            let valSel = sel.getAttribute('data-select');
            if (valSel){
                base.delHandle(opts, 'active');
                base.delHandle(selects, 'active');
                // base.delHandle(imgs, 'active');
                imgs.forEach(function(item:HTMLElement){
                    item.classList.remove('active');
                })

                opts.forEach(function(opt){
                    let valOpt = opt.getAttribute('data-option');
                    if (valOpt && valOpt == valSel){
                        sel.classList.add('active');
                        opt.classList.add('active');
                    }
                })
                imgs.forEach(function(img:HTMLElement){
                    let valOpt = img.getAttribute('data-select');
                    if (valOpt && valOpt == valSel){
                        // sel.classList.add('active');
                        img.classList.add('active');
                    }
                })
            }
            else{
                base.delHandle(opts, 'active');
                base.delHandle(selects, 'active');
                // base.delHandle(imgs, 'active');
                imgs.forEach(function(item:HTMLElement){
                    item.classList.remove('active');
                })
                
                opts.forEach(function(opt, idxOpt){
                    if (idxOpt == idxSel){
                        sel.classList.add('active');
                        opt.classList.add('active');
                    }
                })

                imgs.forEach(function(img:HTMLElement, idxImg){
                    if (idxImg == idxSel){
                        // sel.classList.add('active');
                        img.classList.add('active');
                    }
                })
            }
        })
    })
}

export const about = function(){
    initSwiper()
    statementsHandle()
}