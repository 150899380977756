// import base from "../libraries/_base";


// function tabsHandle(){
//     let tabs = document.querySelectorAll('.n-1-tabs .tab-item');
//     let swOpts = document.querySelectorAll('.n-1-option');
    
//     if (tabs.length > 0){
//         let urlSearchParams = new URLSearchParams(window.location.search);
//         let params = Object.fromEntries(urlSearchParams.entries());

//         if (params.option){
//             tabs.forEach(function(tab, idx){
//                 if (idx + 1 + "" == params.option){
//                     base.delHandle(tabs, 'active');
//                     base.delHandle(swOpts, 'active');
        
//                     tab.classList.add('active');
        
//                     swOpts[idx].classList.add('active');

//                     initPagination();
//                 }
//             })
//         }
//         else{
//             tabs.forEach(function(tab, idx){
//                 if (idx + 1 + "" == '1'){
//                     base.delHandle(tabs, 'active');
//                     base.delHandle(swOpts, 'active');
        
//                     tab.classList.add('active');
        
//                     swOpts[idx].classList.add('active');

//                     initPagination();
//                 }
//             })
//         }

//         tabs.forEach(function(tab, idx){
//             let itemTab = <HTMLElement> tab;
//             itemTab.onclick = function(){
//                 base.delHandle(tabs, 'active');
//                 base.delHandle(swOpts, 'active');
    
//                 tab.classList.add('active');
    
//                 swOpts[idx].classList.add('active');
    
//                 initPagination();

//                 // Lưu lịch sử khi chuyển option
//                 let idxPlus = idx + 1;
//                 history.pushState({}, itemTab.innerText , window.location.pathname + '?option=' + idxPlus);
//             }
//         })
    
//         window.addEventListener('popstate', function(event:any){
//             var state = event.state;

//             let urlSearchParams = new URLSearchParams(window.location.search);
//             let params = Object.fromEntries(urlSearchParams.entries());

//             if (params.option) {
//                 // use it to modify the page 
//                 tabs.forEach(function(tab, idx){
//                     if (idx + 1 + "" == params.option){
//                         base.delHandle(tabs, 'active');
//                         base.delHandle(swOpts, 'active');
            
//                         tab.classList.add('active');
            
//                         swOpts[idx].classList.add('active');

//                         initPagination();
//                     }
//                 })
//              }else{ 
//                 tabs.forEach(function(tab, idx){
//                     if (idx + 1 + "" == '1'){
//                         base.delHandle(tabs, 'active');
//                         base.delHandle(swOpts, 'active');
            
//                         tab.classList.add('active');
            
//                         swOpts[idx].classList.add('active');

//                         initPagination();
//                     }
//                 })
//              }
//         })
//     }

// }

// import pagination from "../libraries/pagination";

// function initPagination(){
//     if (window.innerWidth >= 992){
//         pagination.init({
//             queryClassPagination: '.n-1-option.active .box-pagination',
//             listBox: '.n-1-option.active .n-1-option__item',
//             itemPages: 9,
//             scroll: {
//                 id: 'scrollBoxPagination',
//             }
//         })
//     }
//     else if (window.innerWidth >= 576){
//         pagination.init({
//             queryClassPagination: '.n-1-option.active .box-pagination',
//             listBox: '.n-1-option.active .n-1-option__item',
//             itemPages: 6,
//             scroll: {
//                 id: 'scrollBoxPagination',
//             }
//         })
//     }
//     else{
//         pagination.init({
//             queryClassPagination: '.n-1-option.active .box-pagination',
//             listBox: '.n-1-option.active .n-1-option__item',
//             itemPages: 3,
//             scroll: {
//                 id: 'scrollBoxPagination',
//             }
//         })
//     }
//     let timeOutRs: any;
//     window.addEventListener('resize', function(e){
//         clearTimeout(timeOutRs);
//         setTimeout(function(){
//             if (window.innerWidth >= 992){
//                 pagination.init({
//                     queryClassPagination: '.n-1-option.active .box-pagination',
//                     listBox: '.n-1-option.active .n-1-option__item',
//                     itemPages: 9,
//                     scroll: {
//                         id: 'scrollBoxPagination',
//                     }
//                 })
//             }
//             else if (window.innerWidth >= 576){
//                 pagination.init({
//                     queryClassPagination: '.n-1-option.active .box-pagination',
//                     listBox: '.n-1-option.active .n-1-option__item',
//                     itemPages: 6,
//                     scroll: {
//                         id: 'scrollBoxPagination',
//                     }
//                 })
//             }
//             else{
//                 pagination.init({
//                     queryClassPagination: '.n-1-option.active .box-pagination',
//                     listBox: '.n-1-option.active .n-1-option__item',
//                     itemPages: 3,
//                     scroll: {
//                         id: 'scrollBoxPagination',
//                     }
//                 })
//             }
//         }, 300)
//     })
// }


export const news = function(){
    // tabsHandle();
    // initPagination();
}