function init(){
    let bcitems = document.querySelectorAll('.breadcrumbs-item');
    bcitems.forEach(function(item, index){
        if (index != 0){
            item.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" width="8.242" height="12.243" viewBox="0 0 8.242 12.243">
                <path d="M1760,5246.636l4-4,4,4" transform="translate(5248.757 -1757.879) rotate(90)" fill="none" stroke="#05aceb" stroke-linecap="round" stroke-width="3"/>
            </svg>
            ` + item.innerHTML;
        }
    })
}

export const breadcrumbs = function(){
    init();
}