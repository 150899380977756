declare var Swiper : any;

function initSwiper(){
    if (!document.querySelector('.banner')){
        return;
    }
    var swiper = new Swiper(".banner .swiper", {
        spaceBetween: 0,
        pagination: {
            el: ".banner .swiper .swiper-pagination",
            dynamicBullets: true,
            clickable: true,
        },
        speed: 1200,
        autoplay: {
            delay: 3500,
            disableOnInteraction:false,
        },
        loop: true,

        // effect: 'coverflow',
        // coverflowEffect: {
        //     slideShadows: false,
        // },
    });
}

export const banner = function(){
    initSwiper();
}