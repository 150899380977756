export const tools = function(){
    let wTools = document.querySelector('.tools');
    if (wTools){
        let btn = <HTMLElement>wTools.querySelector('.tools-btn .btn');
        btn.onclick = function(){
            wTools.classList.toggle('show');
        }

        // close handle
        let toolMobbile = document.querySelector('.tools-mobile');
        if (toolMobbile){
            const close =<HTMLElement> toolMobbile.querySelector('.modal-close');
    
            if (close){
                close.onclick = function(e){
                    e.preventDefault();
                    wTools.classList.remove('show');
                }
        
                toolMobbile.addEventListener('click', function(e){
                    e.stopPropagation();
                    let elePopup = <HTMLElement>e.target;
            
                    if (elePopup.classList.contains('tools-mobile')){
                        wTools.classList.remove('show');
                    }
                })
            }
        }
    }
}