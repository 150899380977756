declare var window :any;

export const quickTracking = function(){
    let itemResults = document.querySelectorAll('.qt-1 .results-ls>.item');
    itemResults.forEach(function(item:HTMLElement){
        let icon = item.querySelector('.icon');
        icon.addEventListener('click', function(e){
            e.preventDefault();
            if (item.classList.contains('is-showed')){
                item.classList.remove('is-showed');
            }else{
                let promise = new Promise(function(res, rej){
                    // itemResults.forEach(function(item){
                    //     item.classList.remove('is-showed');
                    // })
                    // setTimeout(function(){
                    // }, 50)
                    return res(true);
                })
    
                promise.then(function(value){
                    if (value){
                        item.classList.add('is-showed');
                        // item.scrollIntoView({
                        //     behavior: 'smooth'
                        // });
    
                        let hHeader = document.querySelector('header');
    
                        let offsetTop = item.offsetTop;
                        let parentElement = <HTMLElement> item.offsetParent;
                        while (parentElement !== null) {
                            offsetTop += parentElement.offsetTop;
                            parentElement = <HTMLElement> parentElement.offsetParent;
                        }
    
                        window.scrollTo({
                            top: offsetTop - hHeader.clientHeight,
                            left: 0,
                            behavior: "smooth"
                        })
                    }
                })
                .catch(function(){
                    console.error('scroll to item error!');
                })
    
            }
        })
        // item.addEventListener('click', function(e){
        //     e.preventDefault();
        // })
    })

    // window.addEventListener('scroll', function(){
    //     itemResults.forEach(function(itemRs){
    //     })
    // })
}