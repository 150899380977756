import pagination from "../libraries/pagination";
import base from "../libraries/_base";

function initPagination(){
    if (document.querySelector('.st-1')){
        if (window.innerWidth >= 992){
            pagination.init({
                queryClassPagination: '.st-1-wrapper .box-pagination',
                listBox: '.st-1-wrapper .st-1-teams .team',
                itemPages: 9,
                scroll: {
                    id: 'scrollBoxPagination',
                }
            })
        }
        else if (window.innerWidth >= 576){
            pagination.init({
                queryClassPagination: '.st-1-wrapper .box-pagination',
                listBox: '.st-1-wrapper .st-1-teams .team',
                itemPages: 6,
                scroll: {
                    id: 'scrollBoxPagination',
                }
            })
        }
        else{
            pagination.init({
                queryClassPagination: '.st-1-wrapper .box-pagination',
                listBox: '.st-1-wrapper .st-1-teams .team',
                itemPages: 3,
                scroll: {
                    id: 'scrollBoxPagination',
                }
            })
        }
        let timeOutRs: any;
        window.addEventListener('resize', function(e){
            clearTimeout(timeOutRs);
            setTimeout(function(){
                if (window.innerWidth >= 992){
                    pagination.init({
                        queryClassPagination: '.st-1-wrapper .box-pagination',
                        listBox: '.st-1-wrapper .st-1-teams .team',
                        itemPages: 9,
                        scroll: {
                            id: 'scrollBoxPagination',
                        }
                    })
                }
                else if (window.innerWidth >= 576){
                    pagination.init({
                        queryClassPagination: '.st-1-wrapper .box-pagination',
                        listBox: '.st-1-wrapper .st-1-teams .team',
                        itemPages: 6,
                        scroll: {
                            id: 'scrollBoxPagination',
                        }
                    })
                }
                else{
                    pagination.init({
                        queryClassPagination: '.st-1-wrapper .box-pagination',
                        listBox: '.st-1-wrapper .st-1-teams .team',
                        itemPages: 3,
                        scroll: {
                            id: 'scrollBoxPagination',
                        }
                    })
                }
            }, 300)
        })
    }
}

export const structure = function(){
    initPagination();
}