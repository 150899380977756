declare var $ : any;

function changeLinkHandle(){
    let frmLink = <HTMLInputElement>document.getElementById('formLinkFooter');
    frmLink.addEventListener('change', function(e){
        let val = frmLink.value;
        let htmlLink = document.createElement('a');
        htmlLink.href = val;
        htmlLink.setAttribute('target', '_blank');
        htmlLink.style.display = 'none';
        document.body.appendChild(htmlLink);
        htmlLink.click();
        htmlLink.remove();
    })

    const fixedFooter = document.querySelector(".footer-fixed");
    if (fixedFooter){
        const thumb = fixedFooter.querySelector('.footer-fixed__thumb');
        thumb.addEventListener('click', function(e){
            e.preventDefault();
            fixedFooter.classList.toggle('is-showed');
        })
    }
}

export const footer = function(){
    changeLinkHandle();
}