import base from "../libraries/_base";

function optTypeShippingHandle(){
    let grOpt = document.querySelectorAll('[data-typeofshipping]');
    let selType = <HTMLInputElement>document.getElementById('typeOfShipping');
    if (!selType){
        return;
    }

    base.delHandle(grOpt, 'active');
    grOpt.forEach(function(opt){
        if (opt.getAttribute('data-typeOfShipping') == selType.value){
            opt.classList.add('active');
        }
    })

    selType.addEventListener('change', function(e){
        base.delHandle(grOpt, 'active');

        grOpt.forEach(function(opt){
            if (opt.getAttribute('data-typeOfShipping') == selType.value){
                opt.classList.add('active');
            }
        })
    })
}


function checkInputExist(list:any){
    let check = true;
    list.forEach(function(item:HTMLInputElement){
        if (!item.value){
            check = false;
            return false;
        }
        return true;
    })
    if (check){
        return true;
    }

    return false;
}


function typeShippingTableHandle(){
    let tableShipping = document.querySelector('[data-typeofshipping="0"] table');
    if(tableShipping){
        let bodyTable = tableShipping.querySelector('tbody');
        let rowTable = bodyTable.querySelectorAll('tr');
        let childHtml = document.createElement('tr');
        rowTable.forEach(function(row){
            if (!row.classList.contains('disabled')){
                childHtml.innerHTML = row.innerHTML;
            }
        })

        rowTable.forEach(function(row){
            if (row.classList.contains('disabled')){
                let inputItems = row.querySelectorAll('.input-item');
                inputItems.forEach(function(itemInput:HTMLInputElement){
                    itemInput.setAttribute('disabled', 'true');
                })

                let blockEvent = <HTMLElement>row.querySelector('.actionEvt button');
                blockEvent.classList.remove('btnAdd');
                blockEvent.classList.add('btnRemove');

                blockEvent.onclick = function(){
                    row.remove();
                }
            }
        })

        rowTable.forEach(function(row){
            let blockEvent = <HTMLElement>row.querySelector('.actionEvt button');
            if (blockEvent.classList.contains('btnAdd')){
                let timeoutClick = false;
                let timeoutCheck:any;
                blockEvent.onclick = function(){
                    clearTimeout(timeoutCheck);
                    let inputItems = row.querySelectorAll('.input-item');

                    if (!checkInputExist(inputItems)){
                        row.style.border = "1px solid red";
                        timeoutCheck = setTimeout(function(){
                            row.style.border = "";
                        }, 2000);
                        return;
                    }
                    if (timeoutClick){
                        return;
                    }
                    row.classList.add('disabled');
                    inputItems.forEach(function(itemInput:HTMLInputElement){
                        itemInput.setAttribute('disabled', 'true');
                    })
    
                    blockEvent.classList.remove('btnAdd');
                    blockEvent.classList.add('btnRemove');

                    blockEvent.style.opacity = ".3";
                    blockEvent.style.cursor = "default";
                    blockEvent.style.pointerEvents = "none";
                    blockEvent.style.userSelect = "none";
                    
                    timeoutClick = true;
                    setTimeout(function(){
                        blockEvent.onclick = function(){
                            row.remove();
                        }
                        bodyTable.appendChild(childHtml);
                        typeShippingTableHandle();

                        blockEvent.style.opacity = "";
                        blockEvent.style.cursor = "";
                        blockEvent.style.pointerEvents = "";
                        blockEvent.style.userSelect = "";
                        timeoutClick = false;
                    }, 600)                    
                }
            }
            else{
                blockEvent.onclick = function(){
                    row.remove();
                }
            }
        })
    }
}

export const submitSi = function(){
    optTypeShippingHandle();
    typeShippingTableHandle();
}