function constants(){
    // add 
    let subTitle = document.querySelectorAll('.braney-subtitle');
    subTitle.forEach(function(sub){
        sub.innerHTML = 
        `<svg xmlns="http://www.w3.org/2000/svg" width="6" height="14.258" viewBox="0 0 6 14.258">
            <g transform="translate(6) rotate(90)">
                <path d="M3.832,0H0L6,6H8.34Z"/>
                <path d="M4.508,0H8.34l-6,6H0Z" transform="translate(5.917)"/>
            </g>
        </svg>` 
        +  sub.innerHTML + 
        `<svg xmlns="http://www.w3.org/2000/svg" width="6" height="14.258" viewBox="0 0 6 14.258">
            <g transform="translate(1118.537 -510.4) rotate(90)">
                <path d="M4290.232,1143.537H4286.4l6-6h2.34Z" transform="translate(-3776 -25)"/>
                <path d="M4290.908,1143.537h3.832l-6-6h-2.34Z" transform="translate(-3770.083 -25)"/>
            </g>
        </svg>`;
    })


    let more = document.querySelectorAll('.braney-more a');
    more.forEach(function(sub){
        sub.innerHTML = 
        `<svg xmlns="http://www.w3.org/2000/svg" width="14.576" height="14.576" viewBox="0 0 14.576 14.576">
            <g id="Group_2288" data-name="Group 2288" transform="translate(-902.545 -869.129)">
                <path id="Path_14007" data-name="Path 14007" d="M891.667,864,902,874.333" transform="translate(13 7.25)" fill="none" stroke="#05aceb" stroke-linecap="round" stroke-width="3"/>
                <path id="Path_14008" data-name="Path 14008" d="M907.071,873v9.5H897.5" transform="translate(7.929 -0.917)" fill="none" stroke="#05aceb" stroke-linecap="round" stroke-width="3"/>
            </g>
        </svg>` 
        +  sub.innerHTML;
    })

    let moreBtn = document.querySelectorAll('.braney-more button');
    moreBtn.forEach(function(btn){
        btn.innerHTML = 
        `<svg xmlns="http://www.w3.org/2000/svg" width="14.576" height="14.576" viewBox="0 0 14.576 14.576">
            <g id="Group_2288" data-name="Group 2288" transform="translate(-902.545 -869.129)">
                <path id="Path_14007" data-name="Path 14007" d="M891.667,864,902,874.333" transform="translate(13 7.25)" fill="none" stroke="#05aceb" stroke-linecap="round" stroke-width="3"/>
                <path id="Path_14008" data-name="Path 14008" d="M907.071,873v9.5H897.5" transform="translate(7.929 -0.917)" fill="none" stroke="#05aceb" stroke-linecap="round" stroke-width="3"/>
            </g>
        </svg>` 
        +  btn.innerHTML;
    })

    let customs = document.querySelectorAll('.nav-bar-item__arrow');
    customs.forEach(function(item){
        item.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="19" viewBox="0 0 8 19">
            <g transform="translate(8) rotate(90)">
                <path d="M4291.319,1145.537H4286.4l7.7-8h3Z" transform="translate(-4286.4 -1137.537)" fill="#05aceb"/>
                <path d="M4292.188,1145.537h4.919l-7.7-8h-3Z" transform="translate(-4278.107 -1137.537)" fill="#05aceb"/>
            </g>
        </svg>
        `;
    })


    let playYtb = document.querySelectorAll('.playYtb');
    playYtb.forEach(function(item){
        item.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88">
        <g id="Group_8655" data-name="Group 8655" transform="translate(-912 -556)">
            <circle id="Ellipse_1830" data-name="Ellipse 1830" cx="44" cy="44" r="44" transform="translate(912 556)" fill="#fff"/>

            <path class="arrowPlay" data-name="Polygon 2" d="M21,0,42,36H0Z" transform="translate(980 579) rotate(90)" fill="#d1d1d1"/>

        </g>
        </svg>
        `;
    })
}

export default constants;