declare var Swiper : any;
import base from "../libraries/_base";

function initSwiper(){
    let nd1Swiper = document.querySelector('.nd-1-relate.active');
    if (nd1Swiper){
        let swNd1 = new Swiper(nd1Swiper.querySelector('.swiper'), {
            allowTouchMove: true, 
            
            navigation: {
                nextEl: nd1Swiper.querySelector('.swiper-custom-button-next'),
                prevEl: nd1Swiper.querySelector('.swiper-custom-button-prev')
            },

            breakpoints:{
                0: {
                    slidesPerView: 1,
                    spaceBetween: 12,
                    allowTouchMove: true, 
                    allowSlideNext: true,
                    allowSlidePrev: true,
                },
                576: {
                    slidesPerView: 1.5,
                    spaceBetween: 10,
                    allowTouchMove: true, 
                    allowSlideNext: true,
                    allowSlidePrev: true,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    allowTouchMove: true, 
                    allowSlideNext: true,
                    allowSlidePrev: true,
                },
                992: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    allowTouchMove: false, 
                    allowSlideNext: false,
                    allowSlidePrev: false,
                },
                1200: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    allowTouchMove: false, 
                    allowSlideNext: false,
                    allowSlidePrev: false,
                },
                1336: {
                    slidesPerView: 1,
                    spaceBetween: 38,
                    allowTouchMove: false,
                    allowSlideNext: false,
                    allowSlidePrev: false, 
                }
            }
        })

        let check = false;
        window.addEventListener('resize', function(){
            if (this.window.innerWidth >= 992 && !check){
                check = true;
                swNd1.slideTo(0);
            }else{
                check = false;
            }
        })
    }
}

function tabsHandle(){
    let tabs = document.querySelectorAll('.nd-1 .nav-bar-item');
    let swOpts = document.querySelectorAll('.nd-1-relate');

    // tabs.forEach(function(itemTemp, idxTemp){
    //     if (idxTemp == 0){
    //         itemTemp.classList.add('active');
    //     }else{
    //         itemTemp.classList.remove('active');
    //     }
    // })
    // swOpts.forEach(function(itemTemp, idxTemp){
    //     if (idxTemp == 0){
    //         itemTemp.classList.add('active');
    //     }else{
    //         itemTemp.classList.remove('active');
    //     }
    // })

    tabs.forEach(function(tab, idx){
        let itemTab = <HTMLElement> tab;
        itemTab.onclick = function(){
            base.delHandle(tabs, 'active');
            base.delHandle(swOpts, 'active');

            tab.classList.add('active');

            swOpts[idx].classList.add('active');


            initSwiper();
        }
    })
}

function copyMarker(){
    let copies = document.querySelectorAll('.copyMaker');
    // console.log(copies);
    copies.forEach(function(copyText){
        // const text = copyText.getAttribute("href") + "";
        const text = window.location.href;
        const textElement = copyText.getAttribute("tooltip") + "";
        const textElementSuccess = copyText.getAttribute("data-tooltip") + "";
        
        // console.log(text);

        copyText.addEventListener("click", function(e){
            e.preventDefault();
            const input = document.createElement("input");
            input.value = text;
            input.setAttribute('type', 'text');
            input.style.height = "0";
            input.style.opacity = "0";
            let body = document.querySelector('body');
            body.appendChild(input);

            input.select();

            document.execCommand("copy");

            input.remove();

            // window.getSelection().selectAllChildren(input);
            // input.execCommand("copy");

            copyText.setAttribute("tooltip", textElementSuccess);

        });
        copyText.addEventListener("mouseover", function(e){
            copyText.setAttribute("tooltip", textElement);
        });
    })

    // let copyText = function(textElement:any) {
    //     window.getSelection().selectAllChildren(textElement);
    //     document.execCommand("copy");
    //     e.target.setAttribute("tooltip", "Copied! ✅");
    // };

    // const resetTooltip = (e) => {
        
    // };

}

export const newsDetail = function(){
    initSwiper();

    tabsHandle();

    copyMarker();
}