declare var $: any;

import pagination from "../libraries/pagination";
import base from "../libraries/_base";

function initPagination() {
	if (document.querySelector(".careers-1")) {
		pagination.init({
			queryClassPagination: ".careers-1-wrapper .box-pagination",
			listBox: ".careers-1-wrapper .careers-1__post .post",
			itemPages: 3,
			scroll: {
				id: "scrollBoxPagination",
			},
		});

		let timeOutRs: any;
		window.addEventListener("resize", function (e) {
			clearTimeout(timeOutRs);
			setTimeout(function () {
				pagination.init({
					queryClassPagination: ".careers-1-wrapper .box-pagination",
					listBox: ".careers-1-wrapper .careers-1__post .post",
					itemPages: 3,
					scroll: {
						id: "scrollBoxPagination",
					},
				});
			}, 300);
		});
	}
}

function showMore() {
	var posts = document.querySelectorAll(".careers-1__post .post");
	posts.forEach(function (item) {
		var more = item.querySelector(".show-more a");
		var postItem = item.querySelector(".post--bottom");
		if (more && postItem) {
			more.addEventListener("click", function (e) {
				e.preventDefault();
				if (more.classList.contains("hideContent")) {
					postItem.classList.remove("hideContent");
					postItem.classList.add("showContent");

					let linkText = more.getAttribute("data-less");
					more.innerHTML = linkText;

					more.classList.remove("hideContent");
					more.classList.add("showContent");
				} else {
					postItem.classList.add("hideContent");
					postItem.classList.remove("showContent");

					let linkText = more.getAttribute("data-show");
					more.innerHTML = linkText;

					more.classList.remove("showContent");
					more.classList.add("hideContent");
				}
			});
		}
	});
	// $(".show-more a").on("click", function() {
	//     var content = document.querySelector("div.post--bottom");

	//     if(linkText === "Show more"){
	//         linkText = "Show less";
	//         content.switchClass("hideContent", "showContent", 400);
	//     } else {
	//         linkText = "Show more";
	//         content.switchClass("showContent", "hideContent", 400);
	//     };

	//     $this.text(linkText);
	// });
}

function callAPI(){
	$('#form-SearchCareer').submit(function (e:any) {
		var typeId = $('#jobTypeInGroup :selected').val();
		e.preventDefault()
		$.ajax({
			type: 'GET',
			url: '/get-list-job-by-type',
			data: { typeId: typeId },
			dataType: 'html',
			success: function (res:any) {
				$('#listJobs').html(res)

				initPagination();
				showMore();
			}
		})
	})
}

export const careers = function () {
	initPagination();
	showMore();

	callAPI();
};
