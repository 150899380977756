function initBtnCustomSwiper(){
    let customs = document.querySelectorAll('.swiper-custom-button');
    customs.forEach(function(item){
        item.innerHTML = `
        <div class="swiper-custom-button-next">
            <svg xmlns="http://www.w3.org/2000/svg" width="21.194" height="8.919" viewBox="0 0 21.194 8.919">
                <path d="M5.7,0H0L8.919,8.919H12.4Z" fill="#0c1e72"/>
                <path d="M6.7,0h5.7L3.478,8.919H0Z" transform="translate(8.796)" fill="#0c1e72"/>
            </svg>
        </div>
        <div class="swiper-custom-button-prev">
            <svg xmlns="http://www.w3.org/2000/svg" width="21.194" height="8.919" viewBox="0 0 21.194 8.919">
                <path d="M5.7,0H0L8.919,8.919H12.4Z" fill="#0c1e72"/>
                <path d="M6.7,0h5.7L3.478,8.919H0Z" transform="translate(8.796)" fill="#0c1e72"/>
            </svg>
        </div>
        `;
    })
}

export const sw = function(){
    initBtnCustomSwiper();
}